/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-dupe-else-if */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-key */
/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-empty-interface */
// import { NextPage, GetStaticPaths } from "next";
import React from "react";

// import { SitePluginFieldsEnum } from "../../../generated/graphql-types";

// export const getStaticProps: GetStaticProps = async () => {};

// export const getStaticPaths: GetStaticPaths = async (s: any) => {
//   console.log("TESTing");
//   console.log(s);
// };

// export const TypePage: NextPage<TypePageProps> = (type) => {
//   let test = "";
//   test = type;
//   console.log(test);
//   return test.slug;
// };
// export default TypePage;

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { graphql, PageProps } from "gatsby";
import { Hero } from "../../components";
// eslint-disable-next-line import/named
import { ResearchComponent } from "../../components/ResearchDocument";
import { buildMenu, Layout } from "../../layouts";
// import { t } from "i18next";
export interface TypePageProps {
  slug: string;
}

const ResearchDocument: React.FC<
  PageProps<
    Pick<
      Query,
      | "allGraphCmsResearchDocument"
      | "graphCmsSectorPage"
      | "allGraphCmsSectorPage"
    >
  >
> = ({
  path,
  slug,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
    allGraphCmsSectorPage: { edges },
  },
}) => {
  let router = slug || "";
  let page = 0;
  router = router?.replace("research", "");
  router = router?.replaceAll("-", " ");
  router = router?.trim();
  const img_title = router;
  let title = router;
  title = router.replace(/\s(.)/g, function ($1) {
    return $1.toUpperCase();
  });
  title = title.charAt(0).toUpperCase() + title.slice(1);
  router = router?.toLowerCase();
  if (typeof window === "undefined" || !window.document) {
    return;
  }

  if (page == 0) {
    // window.location.href = "../../" + slug;
  }
  let socialHead2 = "";
  let titleForHeading = "";

  const inputRef = React.useRef(null);
  const buttonHandler = () => {
    if (inputRef) {
      const serviceValue = document.getElementById("serviceDropDown")?.value;
      const regionDropDown = document.getElementById("regionDropDown")?.value;
      const industryDropDown =
        document.getElementById("industryDropDown")?.value;
      let divbox = "";
      let ALLdivbox = "";
      let Regiondivbox = "";
      let industrydivbox = "";
      divbox = document.getElementsByClassName(serviceValue);
      industrydivbox = document.getElementsByClassName(industryDropDown);
      ALLdivbox = document.getElementsByClassName("ALLDOCUMENT");
      Regiondivbox = document.getElementsByClassName(regionDropDown);
      if (
        industryDropDown == "" &&
        serviceValue == "" &&
        regionDropDown == ""
      ) {
        // NO process
      } else {
        for (let i = 0; i < ALLdivbox.length; i++) {
          ALLdivbox[i].style.display = "none";
        }
        if (
          industryDropDown != "" &&
          serviceValue != "" &&
          regionDropDown != ""
        ) {
          let filter1 = document.getElementsByClassName(
            regionDropDown + "_" + serviceValue + " " + industryDropDown
          );
          for (let i = 0; i < filter1.length; i++) {
            filter1[i].style.display = "block";
          }
        } else {
          if (industryDropDown != "" && serviceValue != "") {
            let filter1 = document.getElementsByClassName(
              serviceValue + "_" + industryDropDown
            );
            for (let i = 0; i < filter1.length; i++) {
              filter1[i].style.display = "block";
            }
          } else {
            if (serviceValue != "" && regionDropDown != "") {
              let filter1 = document.getElementsByClassName(
                regionDropDown + "_" + serviceValue
              );
              for (let i = 0; i < filter1.length; i++) {
                filter1[i].style.display = "block";
              }
            } else if (industryDropDown != "" && regionDropDown != "") {
              let filter1 = document.getElementsByClassName(
                regionDropDown + "_" + industryDropDown
              );
              for (let i = 0; i < filter1.length; i++) {
                filter1[i].style.display = "block";
              }
            } else {
              for (let i = 0; i < ALLdivbox.length; i++) {
                ALLdivbox[i].style.display = "none";
              }
              for (let i = 0; i < divbox.length; i++) {
                divbox[i].style.display = "block";
              }
              for (let i = 0; i < Regiondivbox.length; i++) {
                Regiondivbox[i].style.display = "block";
              }
              for (let i = 0; i < industrydivbox.length; i++) {
                industrydivbox[i].style.display = "block";
              }
            }
          }
        }
      }
    }
  };

  edges.map((values) => {
    if (values.node.sector?.trim().toLowerCase().search(img_title) == -1) {
    } else {
      socialHead2 = values.node.heroImage?.url;
      titleForHeading = values.node.sector;
      return;
    }
  });
  const Image = {
    layout: "constrained",
    backgroundColor: "#282828",
    images: {
      fallback: {
        sizes: "(min-width: 1280px) 1280px, 100vw",
        src: { socialHead2 },
        srcSet: socialHead2,
        sources: [],
        layout: "constrained",
        width: 1280,
      },
    },
    width: 1280,
    height: 720,
  };

  if (titleForHeading == "") {
    titleForHeading = title;
  }
  const heading =
    "A collection of analysis, research and stories about our capabilities from our  Experts.";
  let regionArray: string[] = [];
  let seviceArray: string[] = [];
  let industryArray: string[] = [];
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title={"Insights | Partners in Performance Insights"}
      description={sectorPage?.description}
    >
      <Hero
        title={"Partners in Performance  Insights"}
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <section data-component="Statement" className="custom-class mb-20px">
        <center>
          <h3 className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2 mt-20px lg:mt-0 text-right-margin p3">
            {heading}
          </h3>
        </center>
      </section>
      <section
        data-component="ResearchComponent"
        className="relative max-width z-10 "
      >
        {nodes.map((test) => {
          const regions = test.regions;
          const sectors = test.sectorPages;
          for (let k = 0; k < sectors.length; k++) {
            if (sectors[k].sectorType == "service") {
              if (
                !seviceArray.includes(sectors[k].sector.replaceAll(" ", "_"))
              ) {
                seviceArray.push(sectors[k].sector.replaceAll(" ", "_"));
              }
            } else {
              if (
                !industryArray.includes(sectors[k].sector.replaceAll(" ", "_"))
              ) {
                industryArray.push(sectors[k].sector.replaceAll(" ", "_"));
              }
            }
          }

          for (let i = 0; i < regions.length; i++) {
            if (!regionArray.includes(regions[i])) {
              regionArray.push(regions[i]);
            }
          }
        })}
        <div className="content-grid grid-flow-row-dense">
          <div className="col-span-12 p-20px text-center mb-20px">
            <select id="serviceDropDown" ref={inputRef}>
              <option value="">Select service</option>
              {seviceArray.map((servicevalue) => (
                <option value={servicevalue}>
                  {servicevalue.replaceAll("_", " ")}
                </option>
              ))}
            </select>
            <select id="industryDropDown" className="ml-20px" ref={inputRef}>
              <option value="">Select Industry</option>
              {industryArray.map((industryvalue) => (
                <option value={industryvalue}>
                  {industryvalue.replaceAll("_", " ")}
                </option>
              ))}
            </select>
            <select id="regionDropDown" className="ml-20px" ref={inputRef}>
              <option value="">Select Region</option>
              {regionArray.map((regionvalue) => (
                <option value={regionvalue}>
                  {regionvalue.replaceAll("_", " ")}
                </option>
              ))}
            </select>
            <button className="underline1 ml-10px" onClick={buttonHandler}>
              Filter
            </button>
            <a className="underline2 ml-10px" href="../insights-v1">
              show All
            </a>
          </div>
          {nodes.map((test) => (
            <ResearchComponent
              name={test.documentDescription}
              url={test.documentThumbnail.url}
              DocumentUrl={test.documents.url}
              classnamesvalues={
                "ALLDOCUMENT" +
                " " +
                test.regions.toString().replaceAll(",", " ") +
                " " +
                test.sectorPages
                  .map((t: { sector: any }) => {
                    let testvalues = t.sector.replaceAll(" ", "_");
                    return testvalues;
                  })
                  .toString()
                  .replaceAll(",", " ") +
                test.regions
                  .map((value: any) => {
                    let usedvalues = test.sectorPages.map(
                      (serviceValue: any) => {
                        let values =
                          value +
                          "_" +
                          serviceValue.sector.replaceAll(" ", "_");
                        return values;
                      }
                    );
                    return usedvalues;
                  })
                  .toString()
                  .replaceAll(",", " ") +
                " " +
                test.sectorPages
                  .map((t: { sector: any }) => {
                    let testvalues = t.sector.replaceAll(" ", "_");
                    return testvalues;
                  })
                  .toString()
                  .replaceAll(",", "_") +
                " " +
                test.regions
                  .map((value: any) => {
                    let usedvalues = test.sectorPages.map(
                      (serviceValue: any) => {
                        let values = serviceValue.sector.replaceAll(" ", "_");
                        return values;
                      }
                    );
                    return (
                      value + "_" + usedvalues.toString().replaceAll(" ", "_")
                    );
                  })
                  .toString()
                  .replaceAll(",", " ")
              }
            />
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentLocate
        regions
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
        sectorPages {
          sector
          sectorType
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
    allGraphCmsSectorPage {
      edges {
        node {
          sector
          heroImage {
            id
            url
          }
          pageTitle
        }
      }
    }
  }
`;
